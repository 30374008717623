;(function(){
const NB_CHARS_SPLIT_TITLE = 15;
const NB_CHARS_SPLIT_DESC = 22;
const ROW_HEIGHT_TITLE = 40;
const ROW_HEIGHT_DESC = 21;
const decorateSummary = (object, args) => {
    const splitIntoLines = (input, maxLen) => {
        const words = input.split(' ');
        return words.reduce((acc, word) => {
            let currentLine = acc.pop() || '';
            if (currentLine.length + word.length <= maxLen || currentLine === '') {
                currentLine += currentLine ? ' ' + word : word;
            } else {
                acc.push(currentLine);
                currentLine = word;
            }
            acc.push(currentLine);
            return acc;
        }, []);
    }
    
    if (!object) {
        return;
    }
    let svg = object.getSVGDocument();
    if (args.svgTitle) {
        svg.querySelector('#global-title').innerHTML = args.svgTitle;
    }
    
    ['date', 'client', 'tech', 'budget'].forEach((area) => {
        const svgField = svg.querySelector('#' + area);
        
        if (!args[area]) {
            svgField.style.display = 'none';
            return;
        }
        
        const splittedTitle = splitIntoLines(args[area].title, NB_CHARS_SPLIT_TITLE);
        const splittedDesc = splitIntoLines(args[area].desc, NB_CHARS_SPLIT_DESC);
        
        svgField.innerHTML = '';
        let xPos = parseFloat(svgField.dataset.x);
        let yPos = parseFloat(svgField.dataset.y);
        
        splittedTitle.forEach((line, idx) => {
            // <text id="date-title" transform="matrix(1 0 0 1 3.474 90.5532)" className="title">2022</text>
            const textTitle = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            textTitle.setAttribute('id', area + '-title-r' + parseInt(idx + 1));
            textTitle.setAttribute('transform', 'matrix(1 0 0 1 ' + xPos + ' ' + yPos + ')');
            textTitle.setAttribute('class', 'title');
            textTitle.innerHTML = line;
            yPos += ROW_HEIGHT_TITLE;
            svgField.appendChild(textTitle);
        });
        
        splittedDesc.forEach((line, idx) => {
            textDesc = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            textDesc.setAttribute('id', area + '-desc-r' + parseInt(idx + 1));
            textDesc.setAttribute('transform', 'matrix(1 0 0 1 ' + xPos + ' ' + yPos + ')');
            textDesc.setAttribute('class', 'desc');
            textDesc.innerHTML = line;
            yPos += ROW_HEIGHT_DESC;
            svgField.appendChild(textDesc);
        });
        
    });
}

const svgObject = document.querySelector('object');
if (svgObject) {
    svgObject.addEventListener('load', function(ev) {
        decorateSummary(ev.target, projectDetails);
    });
}

})();